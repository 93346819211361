/* istanbul ignore file */

import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  switchMap,
} from "rxjs";
import {
  checkTermsConsent,
  checkUserConsent,
  TermsConsentCheck,
  updateUserConsent,
  UserConsentCheck,
} from "@yoimo/client-sdk/users";
import {
  getChannelPublicSettings,
  getUserConsentEntry,
  getUserConsentRequests,
} from "@yoimo/client-sdk/channels";

import {
  ChannelTerms,
  UpdateUserConsentRequest,
  UpdateUserConsentResponse,
  UserConsentRequest,
  UserConsentSettings,
} from "@yoimo/interfaces";

import { Firestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { Functions } from "@angular/fire/functions";

type ConsentsGroup = UserConsentSettings["groups"][0] & {
  items: UserConsentRequest[];
};

@Injectable({ providedIn: "root" })
export class ConsentService {
  private hasConsentEntry$ = new BehaviorSubject<boolean | null>(null);

  constructor(private ff: Functions, private fs: Firestore) {}

  getChannelRequests$(
    channelId: string
  ): Observable<[UserConsentRequest[], ChannelTerms[]]> {
    return this._getConsentSettings$(channelId).pipe(
      map((res) => (res ? [res.requests, res.terms] : [[], []]))
    );
  }

  getConsentGroups$(channelId: string): Observable<{
    grouped: ConsentsGroup[];
    ungrouped: UserConsentRequest[];
  } | null> {
    return this._getConsentSettings$(channelId).pipe(
      map((res) => {
        if (!res) return null;

        return {
          ungrouped: res.requests.filter((req) => req.group === null),
          grouped: res.groups.map((group) => ({
            ...group,
            items: res.requests.filter((req) => req.group === group.id),
          })),
        };
      })
    );
  }

  /** Get the response to T&C and general consent requests */
  getConsentResponses$(
    channelId: string,
    userId: string
  ): Observable<[UserConsentCheck, TermsConsentCheck] | null> {
    return combineLatest([
      this._getConsentSettings$(channelId),
      getUserConsentEntry(this.fs, channelId, userId),
      getChannelPublicSettings(this.fs, channelId),
    ]).pipe(
      map(([channelConsents, userConsents, settings]) => {
        if (channelConsents === null) return null;

        return [
          checkUserConsent(settings, userConsents),
          checkTermsConsent(settings, userConsents),
        ];
      })
    );
  }

  /** Check if there are any outstanding terms/consents pending to be accepted */
  isConsentEntryPending$(
    channelId: string,
    userId: string
  ): Observable<boolean> {
    return combineLatest([
      this.getChannelRequests$(channelId),
      this.getConsentResponses$(channelId, userId),
    ]).pipe(
      map(([channelRequests, consentEntry]) => {
        if (!channelRequests.flat().length) {
          return false;
        }

        if (!consentEntry) return false;

        return consentEntry
          .flat()
          .some((check) => check.required.length || check.needUpdate.length);
      })
    );
  }

  updateConsents$(
    payload: UpdateUserConsentRequest
  ): Observable<UpdateUserConsentResponse> {
    return this.hasConsentEntry$.pipe(
      switchMap((shouldMerge) =>
        updateUserConsent(this.ff, { ...payload, merge: !!shouldMerge })
      )
    );
  }

  private _getConsentSettings$(
    channelId: string
  ): Observable<UserConsentSettings | null> {
    return getChannelPublicSettings(this.fs, channelId).pipe(
      map((settings) => {
        if (!settings?.userConsent) return null;
        return getUserConsentRequests(settings);
      })
    );
  }
}
